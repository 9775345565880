import React from "react";

import { StaticImage } from "gatsby-plugin-image";
import Carousel from "react-bootstrap/Carousel";

import {
  slide1,
  slide1webp,
  slide2,
  slide2webp,
  slide3,
  slide3webp,
} from "../../images/home";

import "./Carousel.scss";

export const Slides = () => (
  <Carousel>
    <Carousel.Item>
      <picture>
        <source srcSet={slide1webp} type="image/webp" />
        <img
          className="d-block w-100 full-height"
          src={slide1}
          alt="Camaras de seguridad"
        />
      </picture>
    </Carousel.Item>
    <Carousel.Item>
      <picture>
        <source srcSet={slide2webp} type="image/webp" />
        <img
          className="d-block w-100 full-height"
          src={slide2}
          alt="Guardia de Seguridad privada"
        />
      </picture>
    </Carousel.Item>
    <Carousel.Item>
      <picture>
        <source srcSet={slide3webp} type="image/webp" />
        <img
          className="d-block w-100 full-height"
          src={slide3}
          alt="Guardia de seguridad privada"
        />
      </picture>
    </Carousel.Item>
  </Carousel>
);
