import React from "react";

import { logo } from "../../images/home";
import "./SiteFooter.scss";

const SiteFooter = () => {
  return (
    <div className="footer">
      <div className="footer__contact">
        <div className="text-white footer__contact-employees">EMPLEOS</div>
        <div className="text-white footer__contact-cv">
          Envianos tu curriculm a
        </div>
        <div>
          <a
            href="mailto:vigiterra-mza@hotmail.com"
            target="_blank"
            rel="noreferrer"
            className="footer__contact-mail"
          >
            vigiterra-mza@hotmail.com
          </a>
        </div>
      </div>
      <div className="row">
        <div className="col-sm footer__logo">
          <img src={logo} alt="VIGITERRA" className="footer__logo-img" />
        </div>
        <div className="col-sm text-white footer__services">
          <div className="footer__services-title"> NUESTROS SERVICIOS</div>
          <ul>
            <li>Barrios privados</li>
            <li>Clinicas</li>
            <li>Hospitales</li>
            <li>Locales comerciales</li>
            <li>Edificios</li>
            <li>Obras</li>
          </ul>
        </div>
        <div className="col-sm text-white footer__information">
          <div className="footer__information-title">DÓNDE ESTAMOS?</div>
          <ul>
            <li>2612776037 - 4316322</li>
            <li> vigiterra-mza@hotmail.com</li>
            <li>Tomás Godoy Cruz 1661, Mendoza</li>
            <li>Lunes a Viernes: 9 - 17 hs</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SiteFooter;
