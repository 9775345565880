import React from "react";
import "./Map.scss";
export const Map = () => (
  <iframe
    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13401.216273917658!2d-68.811553!3d-32.890128!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb1e3223627c979b9!2sVIGILANCIA%20VIGITERRA%20SEGURIDAD!5e0!3m2!1ses!2sar!4v1614745083413!5m2!1ses!2sar"
    height="450"
    frameBorder="0"
    allowFullScreen
    aria-hidden="false"
    styles={{ border: 0 }}
    className="google-map"
    title="vigiterra-map"
  ></iframe>
);
