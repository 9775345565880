import React from "react";

import { Helmet } from "react-helmet";

import SiteStructure from "../components/SiteStructure/SiteStructure";
import { Slides } from "../components/Carousel/Carousel";
import { Map } from "../components/Map/Map";
import { IconContext } from "react-icons";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { GrMail } from "react-icons/gr";
import { servicios } from "../images/home/index";

import "./index.scss";
const IndexPage = () => {
  return (
    <>
      <Helmet title="VIGITERRA - VIGILANCIA Y SEGURIDAD">
        <meta charSet="utf-8" />
        <html lang="es" />
        <meta
          name="description"
          content="Vigiterra es una agencia de Seguridad y vigilancia privada en mendoza"
        />
        <meta
          name="keywords"
          content="Vigiterra, Seguridad, Vigilancia, Privada, Mendoza, Empresa"
        />
        <meta
          name="google-site-verification"
          content="WmkaNRnog02h264UN8k8JpiAVaLFLWvCaSi_m_gFamA"
        />
      </Helmet>
      <main>
        <SiteStructure>
          <div className="share-box">
            <a
              className="facebook-icon p-1"
              href="https://www.facebook.com/952388138189856"
              target="_blank"
              rel="noreferrer"
            >
              <IconContext.Provider
                value={{
                  color: "#313f9f",
                  className: "global-class-name",
                  size: 24,
                }}
              >
                <FaFacebook />
              </IconContext.Provider>
            </a>
            <a
              className="whatsapp-icon p-1"
              href="https://wa.me/+5492612566776"
              target="_blank"
              rel="noreferrer"
            >
              <IconContext.Provider
                value={{
                  color: "#313f9f",
                  className: "global-class-name",
                  size: 24,
                }}
              >
                <FaWhatsapp />
              </IconContext.Provider>
            </a>
            <a
              className="email-icon p-1"
              href="mailto:vigiterra-mza@hotmail.com"
              target="_blank"
              rel="noreferrer"
            >
              <IconContext.Provider
                value={{
                  color: "#313f9f",
                  className: "global-class-name",
                  size: 24,
                }}
              >
                <GrMail />
              </IconContext.Provider>
            </a>
            <a
              className="instagram-icon p-1"
              href="https://www.instagram.com/vigiterra.sa/"
              target="_blank"
              rel="noreferrer"
            >
              <IconContext.Provider
                value={{
                  color: "#313f9f",
                  size: 24,
                }}
              >
                <FaInstagram />
              </IconContext.Provider>
            </a>
          </div>
          <div className="margin-navbar d-none d-lg-block"></div>
          <div id="home" className="full">
            <Slides />
          </div>
          <div className="margin-navbar"></div>

          <div id="nosotros" className="full nosotros">
            <div className="nosotros-text">
              <h2 className="d-block d-sm-none">¿Que Hacemos?</h2>
              <h1 className=" nosotros-text-style">
                El objetivo principal de <b>Vigiterra S.A</b> es el
                planeamiento, comprobación y desarrollo de un plan de seguridad
                y vigilancia que sea eficiente.
                <br /> Ponemos todo nuestro esfuerzo y dedicación para que
                encuentres en nosotros una empresa que siempre estará a la
                vanguardia para resolver de la mejor forma cada proyecto.
              </h1>

              <div className="nosotros-text-title">NOS CARACTERIZA</div>
              <ul className=" nosotros-text-style">
                <li>Información oportuna y completa.</li>
                <li>
                  Rapidéz / Velocidad en la modificación de dispositivos y
                  respuestas ante agresiones.
                </li>
                <li>Control sobre la operación, los medios y el personal.</li>
                <li>
                  Cumplimiento de Leyes y disposiciones legales, Nacionales y
                  Provinciales.
                </li>
                <li>
                  Procedimientos claros y sencillos de Administración de
                  Personal.
                </li>
              </ul>
            </div>
          </div>
          <div className="margin-navbar"></div>

          <div id="servicios" className="servicios">
            <h2 className="d-block d-sm-none text-white">SERVICIOS</h2>

            <picture>
              <img src={servicios} className="servicios-img" alt="servicios" />
            </picture>
          </div>

          <div className="margin-navbar"></div>

          <div id="contacto" className="contactenos">
            <h2 className="d-block d-sm-none">CONTACTO</h2>

            <div className="row">
              <div className="col-sm-12 col-md-6">
                <p className="contactenos-text">
                  <br />
                  Ponete en contacto con nosotros
                  <br />
                  <b>RESPONDEMOS A LA BREVEDAD</b>
                </p>
                <p className="contactenos-text">
                  Comunicate con nosotros por el canal que te sea más cómodo y
                  recibí el asesoramiento adecuado sobre nuestros planes y
                  servicios.
                </p>{" "}
                <p className="contactenos-text">
                  2612566776 - 2613989331 - vigiterrasa@gmail.com
                  <br />
                  Tomás Godoy Cruz 1661, Mendoza
                  <br />
                  Lunes a Viernes: 9 - 17 hs
                </p>
                <div className="mt-4">
                  {" "}
                  <a
                    className="contactenos-button"
                    href="mailto:vigiterra-mza@hotmail.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Solicitar Presupuesto
                  </a>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 mt-5 mt-md-0">
                <Map />
              </div>
            </div>
          </div>
        </SiteStructure>
      </main>
    </>
  );
};

export default IndexPage;
