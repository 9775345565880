import React from "react";
import Navbar from "react-bootstrap/Navbar";

import Nav from "react-bootstrap/Nav";

import { AnchorLink } from "gatsby-plugin-anchor-links";

import { logo } from "../../images/home";

import "./siteHeader.scss";

const SiteHeader = () => {
  return (
    <div className="site-header">
      <Navbar collapseOnSelect expand="lg" variant="dark" fixed="top">
        <AnchorLink to="/#home">
          <Navbar.Brand className="logo">
            <img src={logo} alt="vigiterra logo" className="logo__img" />
          </Navbar.Brand>
        </AnchorLink>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
            <AnchorLink to="/#home">
              <Nav.Link className="text-white" href="/#home">
                HOME
              </Nav.Link>
            </AnchorLink>
            <AnchorLink to="/#nosotros">
              <Nav.Link className="text-white" href="/#nosotros">
                NOSOTROS
              </Nav.Link>
            </AnchorLink>
            <AnchorLink to="/#servicios">
              <Nav.Link className="text-white" href="/#servicios">
                SERVICIOS
              </Nav.Link>
            </AnchorLink>
            <AnchorLink to="/#contacto">
              <Nav.Link className="text-white" href="/#contacto">
                CONTACTO
              </Nav.Link>
            </AnchorLink>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default SiteHeader;
