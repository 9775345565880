import React from "react";

import Header from "../SiteHeader/SiteHeader";
import SiteFooter from "../siteFooter/SiteFooter";
import "./SiteStructure.scss";

const SiteStructure = ({ children }) => {
  return (
    <div className="complete-heigth">
      <Header />
      {children}
      <SiteFooter />
    </div>
  );
};

export default SiteStructure;
